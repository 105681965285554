@import './variables';
@import './mixins';

html,
body {
  font: 14px/1.3 'Source Sans Pro', sans-serif;
  background: white;
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

h1,
h2,
h3 {
  font-family: 'Bree Serif', serif;
}

p {
  margin: 1rem 0;
  padding: 0;
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}

#container {
  width: 100%;
  max-width: 35rem;
  z-index: 10;
}

.hi-there {
  display: flex;
  flex-flow: row no-wrap;
  justify-content: center;
  border-radius: 100%;
  align-items: center;

  .me {
    $size: 3.5rem;

    margin-right: 0.75rem;
    background-image: url("./me.jpg");
    background-repeat: no-repeat;
    width: $size;
    height: $size;
    border: 1px solid $color-accent;
    border-radius: $size;
    background-size: 160%;
    background-position: center center;

    &:hover {
      background-image: url("./awesome.svg");
      border-color: transparent;
      background-size: 100%;
    }
  }

  h1 {
    font-weight: 900;
    margin: 0;
    padding: 0;
    font-size: 2rem;
  }
}

.who-am-i {
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  p {
    margin: 1rem 0;
  }

  ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
  }

  li {
    margin-right: 1rem;
  }

  li:last-child {
    margin-right: 0;
  }

  a {
    white-space: nowrap;
    padding: 0.2rem 0.5rem;
    display: block;
    margin-bottom: 0.3rem;
  }
}
