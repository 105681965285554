@media print {
  .screen-only {
    display: none;
  }
}

html, body {
  height: 100%;
  background: #fff;
  font: 14px / 1.3 Source Sans Pro, sans-serif;
  overflow: hidden;
}

body {
  text-align: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
  padding: 1rem;
  display: -ms-flexbox;
  display: flex;
}

h1, h2, h3 {
  font-family: Bree Serif, serif;
}

p {
  margin: 1rem 0;
  padding: 0;
}

canvas {
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
}

#container {
  width: 100%;
  max-width: 35rem;
  z-index: 10;
}

.hi-there {
  flex-flow: row no-wrap;
  border-radius: 100%;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.hi-there .me {
  width: 3.5rem;
  height: 3.5rem;
  background-image: url("me.0b3bc5b2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 160%;
  border: 1px solid #7725a9;
  border-radius: 3.5rem;
  margin-right: .75rem;
}

.hi-there .me:hover {
  background-image: url("awesome.8dfcb183.svg");
  background-size: 100%;
  border-color: rgba(0, 0, 0, 0);
}

.hi-there h1 {
  margin: 0;
  padding: 0;
  font-size: 2rem;
  font-weight: 900;
}

.who-am-i ul, .who-am-i li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.who-am-i p {
  margin: 1rem 0;
}

.who-am-i ul {
  -ms-flex-flow: wrap;
  flex-flow: wrap;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  display: -ms-flexbox;
  display: flex;
}

.who-am-i li {
  margin-right: 1rem;
}

.who-am-i li:last-child {
  margin-right: 0;
}

.who-am-i a {
  white-space: nowrap;
  margin-bottom: .3rem;
  padding: .2rem .5rem;
  display: block;
}

/*# sourceMappingURL=index.5b81484a.css.map */
