@use "sass:color";
@import './variables';

@mixin md {
  @media print, screen and (min-width: 768px) {
    @content;
  }
}

@mixin screen {
  @media screen {
    @content;
  }
}

@media print {
  .screen-only {
    display: none;
  }
}

@mixin link-color($color) {
  color: $color;

  &:hover {
    color: color.scale($color, $lightness: $link-hover-amount);
  }
}

@mixin subdued {
  opacity: 0.75;
}
